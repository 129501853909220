[data-v-15fe123c] .avue-crud__menu {
  display: none;
}
.input-with-select[data-v-15fe123c] .el-input-group__append {
  background-color: #fff;
  padding: 0 2px;
}
.drug-table[data-v-15fe123c] {
  margin-top: 10px;
  padding: 0 12px;
}
.drug-table .right-title[data-v-15fe123c] {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}
.drug-table .table-header[data-v-15fe123c] {
    height: 38px;
    line-height: 38px;
    text-align: center;
    background-color: #e0f0fd;
    font-size: 14px;
    color: #3b94ff;
    font-weight: 600;
}
.drug-table .table-box .table-contain[data-v-15fe123c] {
    background-color: #f3f4f6;
    font-size: 14px;
}
.drug-table .table-box .table-contain[data-v-15fe123c]:hover {
      background-color: #f2f9ff;
}
.drug-table .table-box .table-contain .name-west[data-v-15fe123c] {
      padding: 10px 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.drug-table .table-box .table-contain .name-west[data-v-15fe123c] .el-image {
        height: 18px;
        width: 18px;
        vertical-align: -4px;
        cursor: pointer;
        margin-right: 4px;
        -ms-flex-negative: 0;
            flex-shrink: 0;
}
.drug-table .table-box .table-contain .name-west .drug-name[data-v-15fe123c] {
        margin-right: 10px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
}
.drug-table .table-box .table-contain .name-west .drug-spec[data-v-15fe123c] {
        -ms-flex-negative: 0;
            flex-shrink: 0;
}
.drug-table .table-box .table-contain .name-west i[data-v-15fe123c] {
        color: #e20000;
        font-size: 16px;
        cursor: pointer;
        margin-left: 10px;
}
.drug-table .table-box .table-contain .name-Record > span[data-v-15fe123c] {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
}
.drug-table .table-box .table-contain .content-total .el-col[data-v-15fe123c],
    .drug-table .table-box .table-contain .table-content .el-col[data-v-15fe123c] {
      padding: 0 6px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
}
.drug-table .table-box .table-contain .content-total .el-col[data-v-15fe123c] .el-input__inner,
      .drug-table .table-box .table-contain .table-content .el-col[data-v-15fe123c] .el-input__inner {
        padding: 0 5px;
        border-radius: 0;
        text-overflow: ellipsis;
}
.drug-table .table-box .table-contain .content-total .el-col[data-v-15fe123c] .el-select .el-input__inner,
      .drug-table .table-box .table-contain .table-content .el-col[data-v-15fe123c] .el-select .el-input__inner {
        padding-right: 20px;
}
.drug-table .table-box .table-contain .content-total .el-col[data-v-15fe123c] .el-select .el-select__caret,
      .drug-table .table-box .table-contain .table-content .el-col[data-v-15fe123c] .el-select .el-select__caret {
        color: #3b94ff;
}
.drug-table .table-box .table-contain .content-total .el-col[data-v-15fe123c] .el-input__suffix,
      .drug-table .table-box .table-contain .table-content .el-col[data-v-15fe123c] .el-input__suffix {
        right: 0;
}
.drug-table .table-box .table-contain .content-total .el-col .dosage-input[data-v-15fe123c] .el-input__inner,
      .drug-table .table-box .table-contain .table-content .el-col .dosage-input[data-v-15fe123c] .el-input__inner {
        border-right: none;
        text-align: right;
}
.drug-table .table-box .table-contain .content-total .el-col .usage-select[data-v-15fe123c] .el-input__inner,
      .drug-table .table-box .table-contain .table-content .el-col .usage-select[data-v-15fe123c] .el-input__inner {
        text-align: center;
}
.drug-table .table-box .table-contain .content-total .el-col .delete[data-v-15fe123c],
      .drug-table .table-box .table-contain .table-content .el-col .delete[data-v-15fe123c] {
        color: #e20000;
        cursor: pointer;
        font-size: 16px;
}
.drug-table .table-box .table-contain .table-content[data-v-15fe123c] {
      height: 34px;
      line-height: 32px;
      text-align: center;
}
.drug-table .table-box .table-contain .content-total[data-v-15fe123c] {
      margin-top: 2px;
      color: #999;
}
.drug-table .table-box .table-contain .content-total .remark[data-v-15fe123c] {
        text-indent: 1em;
}
.drug-table .table-box .table-contain .content-total .remark > div[data-v-15fe123c] {
          height: 20px;
          line-height: 20px;
}
.drug-table .table-box .table-contain .content-total .el-row[data-v-15fe123c] {
        height: 100%;
}
.drug-table .table-box .table-contain .content-total.total-chinese[data-v-15fe123c] {
        padding: 0 12px;
}
.drug-table .table-box .table-contain .warnings[data-v-15fe123c] {
      color: #e20000;
}
.drug-table .table-btn[data-v-15fe123c] {
    margin-top: 10px;
}
.drug-table .supple-instruct[data-v-15fe123c] {
    font-size: 14px;
    margin-top: 20px;
}
.drug-table .supple-instruct[data-v-15fe123c] .el-textarea__inner {
      font-size: 14px;
}
@media screen and (max-width: 1550px) {
.drug-table[data-v-15fe123c] {
    margin-top: 10px;
}
}
