[data-v-75ceacd4] .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.aide-home-drug[data-v-75ceacd4] {
  height: 100%;
}
.aide-home-drug .drug-active[data-v-75ceacd4] {
    height: 32px !important;
}
.aide-home-drug[data-v-75ceacd4] .el-tabs__header {
    margin-top: 2px;
}
.aide-home-drug[data-v-75ceacd4] .el-tabs__item {
    color: #333;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
}
.aide-home-drug[data-v-75ceacd4] .el-tabs__item.is-active {
    color: #3b94ff;
}
.aide-home-drug[data-v-75ceacd4] .el-tabs__active-bar {
    background-color: #3b94ff;
}
.aide-home-drug[data-v-75ceacd4] .el-tabs__nav-wrap::after {
    background: #fff;
}
.aide-home-drug .drug-list[data-v-75ceacd4] {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.aide-home-drug .drug-list .drug-detail[data-v-75ceacd4] {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      font-size: 12px;
      overflow: auto;
}
.aide-home-drug .drug-list .drug-detail .line-left[data-v-75ceacd4] {
        text-align: right;
        font-weight: 600;
        height: 32px;
        line-height: 32px;
        padding-right: 16px;
        border-top: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
}
.aide-home-drug .drug-list .drug-detail .line-right[data-v-75ceacd4] {
        height: 32px;
        line-height: 18px;
        text-align: left;
        padding: 12px 12px 12px 18px;
        border-top: 1px solid #d8d8d8;
}
.aide-home-drug .drug-list .drug-detail .drug-img[data-v-75ceacd4] {
        text-align: right;
        padding: 16px;
}
.aide-home-drug .drug-list .drug-detail .drug-desc[data-v-75ceacd4] {
        text-align: left;
        font-size: 12px;
        padding-left: 18px;
}
.aide-home-drug .drug-list .drug-detail .drug-desc .desc-title[data-v-75ceacd4] {
          font-weight: 600;
          margin-bottom: 10px;
}
.aide-home-drug .drug-list .drug-detail .drug-desc .desc-text[data-v-75ceacd4] {
          margin-bottom: 5px;
}
.aide-home-drug .drug-list .drug-detail .text-left[data-v-75ceacd4] {
        text-align: right;
        height: 32px;
        line-height: 32px;
        font-weight: 600;
        padding-right: 16px;
}
.aide-home-drug .drug-list .drug-detail .text-right[data-v-75ceacd4] {
        text-align: left;
        height: 32px;
        line-height: 32px;
        padding-left: 18px;
}
