[data-v-2c05cd44] .el-col-3 {
  width: 140px !important;
}
[data-v-2c05cd44] .el-col-4 {
  width: 210px !important;
}
[data-v-2c05cd44] .el-radio__label:hover {
  color: #409eff;
}
[data-v-2c05cd44] .el-radio__input {
  display: none;
}
[data-v-2c05cd44] .el-form-item__content {
  margin-left: 0 !important;
}
[data-v-2c05cd44] .el-form-item {
  margin-bottom: 0 !important;
}
[data-v-2c05cd44] .el-button {
  width: 100px;
}
[data-v-2c05cd44] .avue-form__group--flex {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.line-echarts[data-v-2c05cd44] {
  background-color: #fff;
  height: 380px;
  border-radius: 14px;
}
.line-echarts .line-echarts-search[data-v-2c05cd44] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 26px;
}
.line-echarts .line-echarts-search .line-echarts-title[data-v-2c05cd44] {
      padding-left: 20px;
      width: 20%;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 22px;
      color: #333333;
      font-weight: 600;
}
.line-echarts .line-echarts-search[data-v-2c05cd44] .avue-form {
      width: auto !important;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
}
