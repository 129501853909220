[data-v-629be9b1] .el-dialog__body {
  padding-top: 0;
  padding-bottom: 10px;
}
.online-doctor[data-v-629be9b1] {
  height: calc(100vh - 210px);
  overflow-y: auto;
}
.online-doctor .doctor-item[data-v-629be9b1] {
    position: relative;
    height: 140px;
    background-color: #f3f8ff;
    border-radius: 8px;
    margin-bottom: 10px;
}
.online-doctor .doctor-item .item-header[data-v-629be9b1] {
      padding-left: 10px;
      padding-top: 25px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
}
.online-doctor .doctor-item .item-header .doctor-image[data-v-629be9b1] {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        margin-right: 20px;
}
.online-doctor .doctor-item .item-header .online[data-v-629be9b1] {
        position: absolute;
        left: 60px;
        top: 60px;
}
.online-doctor .doctor-item .item-header .high-risk[data-v-629be9b1] {
        position: absolute;
        top: 0;
        left: 10px;
}
.online-doctor .doctor-item .item-header .doctor-info[data-v-629be9b1] {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        height: 70px;
        border-bottom: 1px solid #eae8e8;
}
.online-doctor .doctor-item .item-header .doctor-info .doctor-content[data-v-629be9b1] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          padding: 5px 0;
}
.online-doctor .doctor-item .item-header .doctor-info .doctor-content .doctor-name[data-v-629be9b1] {
            font-size: 14px;
            font-weight: 600;
            margin-right: 10px;
}
.online-doctor .doctor-item .item-header .doctor-info .doctor-content .doctor-position[data-v-629be9b1],
          .online-doctor .doctor-item .item-header .doctor-info .doctor-content .doctor-type[data-v-629be9b1] {
            font-size: 12px;
            color: #999999;
            margin-right: 10px;
}
.online-doctor .doctor-item .item-header .doctor-info .doctor-content .profession[data-v-629be9b1] {
            padding: 2px 6px;
            border: 1px solid #fd9c12;
            border-radius: 4px;
            color: #fd9c12;
}
.online-doctor .doctor-item .item-header .doctor-info .doctor-content .only-tip[data-v-629be9b1] {
            font-size: 12px;
            padding: 2px 6px;
            border: 1px solid #11c529;
            border-radius: 4px;
            color: #11c529;
            margin-right: 10px;
}
.online-doctor .doctor-item .item-header .doctor-info .doctor-content .only-tip.red[data-v-629be9b1] {
              border-color: #f00;
              color: #f00;
}
.online-doctor .doctor-item .item-header .doctor-info .doctor-belong[data-v-629be9b1] {
          font-size: 14px;
}
.online-doctor .doctor-item .item-btn[data-v-629be9b1] {
      text-align: right;
      padding: 10px;
}
.online-doctor .doctor-item .item-btn .el-button[data-v-629be9b1] {
        color: #3b94ff;
        border: 1px solid #3b94ff;
}
.online-doctor .doctor-item .item-btn .el-button[data-v-629be9b1]:hover {
          color: #fff;
          background-color: #3b94ff;
}
.online-doctor .doctor-item .line-up[data-v-629be9b1] {
      position: absolute;
      right: 0;
      font-size: 10px;
      border-radius: 10px 0 0 10px;
      height: 20px;
      line-height: 20px;
      background-color: #fff;
      padding: 0 10px;
}
.online-doctor .doctor-item .line-up span[data-v-629be9b1] {
        color: #f00;
}
.online-doctor .doctor-item .line-up.line1[data-v-629be9b1] {
        top: 10px;
}
.online-doctor .doctor-item .line-up.line2[data-v-629be9b1] {
        top: 35px;
}
.online-doctor .doctor-item .line-up.line3[data-v-629be9b1] {
        top: 60px;
}
