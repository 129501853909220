.total-box[data-v-23eb5d54] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.total-box .total-item[data-v-23eb5d54] {
    background-color: #3b94ff;
    border-radius: 14px;
    color: #ffffff;
    height: 146px;
    width: 49%;
    cursor: pointer;
}
.total-box .total-item[data-v-23eb5d54]:nth-child(2n) {
      background-color: #00d3ba;
}
.total-box .total-item .total-title[data-v-23eb5d54] {
      font-size: 14px;
      font-weight: 600;
      padding-top: 16px;
      padding-left: 32px;
      padding-bottom: 30px;
      color: #fff;
}
.total-box .total-item .total-main[data-v-23eb5d54] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.total-box .total-item .total-main .total-main-item[data-v-23eb5d54] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        text-align: center;
}
.total-box .total-item .total-main .total-main-item .total-count[data-v-23eb5d54] {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 12px;
}
.total-box .total-item .total-main .total-main-item .total-tip[data-v-23eb5d54] {
          font-size: 14px;
          font-weight: 400;
}
.menu[data-v-23eb5d54] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 14px;
  padding: 20px;
  height: calc(100vh - 304px);
}
.menu .menu-item[data-v-23eb5d54] {
    width: 200px;
    height: 120px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
}
.menu .menu-item[data-v-23eb5d54]:hover {
      color: #3b94ff;
}
