[data-v-6f1c1852] .el-table td.el-table__cell,[data-v-6f1c1852] .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
[data-v-6f1c1852] .el-table::before {
  height: 0px;
}
[data-v-6f1c1852] .el-table__fixed-right::before,
.el-table__fixed[data-v-6f1c1852]::before {
  height: 0px;
}
[data-v-6f1c1852] .avue-crud__menu {
  display: none;
}
.common-word .search-content[data-v-6f1c1852] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
.common-word .search-content .el-input[data-v-6f1c1852] {
    width: 240px;
    margin-right: 10px;
}
.common-word .form[data-v-6f1c1852] {
  background-color: #f9f9f9;
  height: 262px;
  padding: 0 30px;
}
.common-word .form .form-header[data-v-6f1c1852] {
    height: 40px;
    line-height: 40px;
    text-align: center;
}
.common-word .form .form-botton[data-v-6f1c1852] {
    text-align: center;
    margin: 20px 0;
}
.common-word .form .form-botton .el-button[data-v-6f1c1852] {
      width: 100px;
}
.common-word .text-delete[data-v-6f1c1852] {
  color: #999;
  margin-left: 20px;
  margin-right: 20px;
}
.common-word .text-delete[data-v-6f1c1852]:hover {
    color: #e02020;
}
