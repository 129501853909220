[data-v-da74ba96] .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.aide-set[data-v-da74ba96] {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  overflow-y: scroll;
}
.aide-set .first-set[data-v-da74ba96] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}
.aide-set .setNotice[data-v-da74ba96] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 600;
}
.aide-set .short-key[data-v-da74ba96] {
    margin-bottom: 20px;
    text-align: left;
    color: #333;
}
.aide-set .short-key .key-title[data-v-da74ba96] {
      font-weight: 600;
      color: #333333;
}
.aide-set .short-key div[data-v-da74ba96] {
    margin-top: 10px;
}
.aide-set .huashu-set[data-v-da74ba96] {
    border: 1px solid #d8d8d8;
    border-radius: 8px;
}
.aide-set .huashu-set .huashu-body[data-v-da74ba96] {
      padding: 16px 12px;
}
.aide-set .huashu-set .huashu-body .huashu-list[data-v-da74ba96] {
        margin-bottom: 30px;
}
.aide-set .huashu-set .huashu-body .huashu-list .huashu-title[data-v-da74ba96] {
          text-align: left;
          font-weight: 600;
}
.aide-set .huashu-set .huashu-body .huashu-list .huashu-common[data-v-da74ba96] {
          text-align: left;
          margin-left: 12px;
          margin-top: 8px;
          line-height: 20px;
}
.aide-set .huashu-set .huashu-body .huashu-list .huashu-custom[data-v-da74ba96] {
          width: 100%;
          margin-top: 10px;
}
.aide-set .huashu-set .huashu-body .set-btn[data-v-da74ba96] {
        margin: 38px 0;
}
