[data-v-00743cec] .avue-crud__menu {
  display: none;
}
[data-v-00743cec] .el-dialog__body {
  padding: 10px 20px;
}
.temp-title[data-v-00743cec] {
  font-weight: bolder;
  margin: 10px 0;
}
.diagnose-content[data-v-00743cec] {
  margin-left: 10px;
}
.use-drug-detail[data-v-00743cec] {
  color: #333;
  overflow-y: auto;
}
.drug-list[data-v-00743cec] {
  padding: 10px 15px;
  border: 1px solid #333;
  margin-bottom: 16px;
}
.drug-list > div[data-v-00743cec]:first-child {
    margin-bottom: 12px;
}
