.el-icon-close[data-v-907bf384] {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}
.el-icon-close[data-v-907bf384]:hover {
    color: #f00;
}
.text-chat[data-v-907bf384] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: auto;
  background-color: #f3f4f6;
}
.text-chat .chat-content[data-v-907bf384] {
    border-bottom: #dddddd 1px solid;
    overflow: auto;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #333333;
    font-size: 14px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.text-chat .chat-content .top-time[data-v-907bf384] {
      text-align: center;
      padding-top: 12px;
      font-size: 12px;
      color: #999999;
      letter-spacing: 0;
      font-weight: 400;
}
.text-chat .chat-content .top-content[data-v-907bf384] {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 12px;
}
.text-chat .chat-content .top-content .red-notice[data-v-907bf384] {
        color: #f11111;
}
.text-chat .chat-content .info-list[data-v-907bf384] {
      font-size: 14px;
      padding: 10px 0 0;
}
.text-chat .chat-content .info-list .list-item[data-v-907bf384] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 20px;
}
.text-chat .chat-content .info-list .list-item .info-image[data-v-907bf384] {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          overflow: hidden;
}
.text-chat .chat-content .info-list .list-item .info-content[data-v-907bf384] {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          min-width: 120px;
          margin-left: 10px;
          max-width: 100%;
}
.text-chat .chat-content .info-list .list-item .info-content:hover .chat-time[data-v-907bf384] {
            display: inline-block;
}
.text-chat .chat-content .info-list .list-item .info-content .chat-time[data-v-907bf384] {
            font-size: 12px;
            display: none;
}
.text-chat .chat-content .info-list .list-item .info-content .time-line[data-v-907bf384] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            color: #999;
            margin-bottom: 4px;
}
.text-chat .chat-content .info-list .list-item .info-content .msg[data-v-907bf384] {
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            max-width: 90%;
            padding: 10px;
            border-radius: 5px;
            word-wrap: break-word;
            word-break: normal;
            background-color: #fff;
}
.text-chat .chat-content .info-list .list-item.doctor[data-v-907bf384] {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: reverse;
              -ms-flex-direction: row-reverse;
                  flex-direction: row-reverse;
}
.text-chat .chat-content .info-list .list-item.doctor .info-content[data-v-907bf384] {
            margin-right: 10px;
}
.text-chat .chat-content .info-list .list-item.doctor .time-line[data-v-907bf384] {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
                -ms-flex-direction: row-reverse;
                    flex-direction: row-reverse;
}
.text-chat .chat-content .info-list .list-item.doctor .msg[data-v-907bf384] {
            background-color: #cbe7ff;
            float: right;
}
.text-chat .chat-content .stystem-tip[data-v-907bf384] {
      margin-bottom: 10px;
}
.text-chat .chat-content .right-menu[data-v-907bf384] {
      position: fixed;
      z-index: 999;
      padding: 5px;
      background-color: #fff;
      -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.text-chat .chat-content .right-menu .menu-item[data-v-907bf384] {
        display: block;
        cursor: pointer;
        padding: 2px 4px;
        border-radius: 3px;
        color: #333;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
}
.text-chat .chat-content .right-menu .menu-item[data-v-907bf384]:hover {
          background-color: #f00;
          color: #fff;
}
.text-chat .text-submit[data-v-907bf384] {
    height: 25%;
    background: #f3f4f6;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.text-chat .text-submit .content-zoom[data-v-907bf384] {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.text-chat .text-submit .content-zoom[data-v-907bf384] .el-textarea {
        height: 100%;
}
.text-chat .text-submit .content-zoom[data-v-907bf384] .el-textarea__inner {
        height: 100%;
        border: none;
        color: #333333;
        background: #f3f4f6;
        padding: 10px 5px 0 15px;
        line-height: 1.2;
}
.text-chat .text-submit .btn-zoom[data-v-907bf384] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      position: relative;
      padding-bottom: 12px;
      padding-right: 30px;
}
.text-chat .text-submit .btn-zoom .cut-btn[data-v-907bf384] {
        position: absolute;
        cursor: pointer;
        width: 18px;
        height: 18px;
        left: 20px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAEgAAAACaqbJVAAACcElEQVQ4EW1UTYhSURT2PX1vRkIbwUDoRwqGZlG7WRYMAy2KoGjZQloIKrhw4TYwJhiEaBYa/kAI0qJlTbuZbSE0s3OtERoYqIuUUd8bfX3nNEfee3nhvnPu93333HPPuTyPByOZTH4jq5BjWdYaLXgkEolTcrxAlcFgsOXt9Xqni8XimaooSh1MXyEKG094Az5EeElLMgFhr7KPiHuYH2mhSHhm8FHJSafTtzRNU8hnoFgstnEIrf8N2iZbWZZKpe7jtAMR2Gy3Uqk8pYP2AD4kIp/P32m1WiPyq9XqT7I0fJib7OHT7XbPiJREBOfjEO0TgGsC2i2O22YRgSjLDjLPYG6gLIcg34pYIn0FsC6gWIiflMvlXyqO+rBKQEJE/UyWEt8iJxaLXff7/dQsq9FoDJvNJt8SaTwmEY96vd6hW5mmaVGpRYT67XK5Raiq6vIiNqzjEM1ms4WQYiORyDtV1/VdAcRSIy/8o1wuZ3D4bDZ7aTQaHYPQRYj8X6P6VGSPIwc0+jYSfQmcbywb3Bb1O8Yl9wuFwh/hONBFgPcA/yu6CFdZBGzj4BfI6oxeO7X1jVsYj8dv1Gq1znw+t71Wt4rXBvr0SEWQjJsOBAK+YDCohcNhLRQK8YxGo+vUK7cWa30ymaR8CEQPzMGjqOeGYVjD4RC9Njmj8Xh87hA5F5d9CHII7LkT93ja7fYYndpEIO43dAr67Ec9b5ZKpR92PagvnArqtI/MHtjJVT5dtd/vm3YO+/gJLO+EYPQHOQCxqg72veIf4RG+whOYEbAMJCzZTCazMZ1O7yHoXcwrSN3A/A3qO653Qq/Urif/LzODFSgQYNHfAAAAAElFTkSuQmCC);
        background-size: cover;
}
.text-chat .text-submit .btn-zoom[data-v-907bf384] .el-button {
        margin-top: 10px;
        width: 96px;
}
.text-chat .text-submit .btn-zoom[data-v-907bf384] .el-button .el-image {
          vertical-align: middle;
          margin-right: 6px;
          height: 12px;
          width: 12px;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
.text-chat .chat-content[data-v-907bf384] {
    font-size: 12px;
}
}
