.inquiry-spec[data-v-8cc31660] {
  overflow-y: auto;
}
.inquiry-spec .search[data-v-8cc31660] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
}
.inquiry-spec .search .el-input[data-v-8cc31660] {
      width: 240px;
      margin-right: 10px;
}
[data-v-8cc31660] .el-tree {
  height: 570px;
  overflow-y: auto;
}
.custom-tree-node[data-v-8cc31660] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.custom-tree-node[data-v-8cc31660] .el-tree-node {
    overflow: hidden;
}
.custom-tree-node .label[data-v-8cc31660] {
    width: 450px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
