[data-v-4253eb98] .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.aide-set[data-v-4253eb98] {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  overflow-y: scroll;
}
.aide-set .setNotice[data-v-4253eb98] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 600;
}
.aide-set .short-key[data-v-4253eb98] {
    margin-bottom: 20px;
    text-align: left;
    color: #333;
}
.aide-set .short-key .key-title[data-v-4253eb98] {
      font-weight: 600;
      color: #333333;
}
.aide-set .short-key div[data-v-4253eb98] {
    margin-top: 10px;
}
