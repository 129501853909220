[data-v-7b1c3f4a] .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #f2f2f2;
}
.top-content[data-v-7b1c3f4a] {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}
.chat-history[data-v-7b1c3f4a] {
  padding: 20px 0;
  height: calc(100vh - 12vh - 54px);
  overflow-y: auto;
}
.chat-history .chat-item[data-v-7b1c3f4a] {
    line-height: 26px;
    padding: 10px 20px;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 30px;
}
.chat-history .history-item[data-v-7b1c3f4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.chat-history .history-item .img .el-image[data-v-7b1c3f4a] {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      overflow: hidden;
}
.chat-history .history-item .msg[data-v-7b1c3f4a] {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 10px;
      border-radius: 5px;
      background-color: #ffffff;
      margin: 0 20px;
}
.chat-history .history-item.without[data-v-7b1c3f4a] {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
}
.chat-history .history-item.patient[data-v-7b1c3f4a] {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
}
.chat-history .history-item.patient .msg[data-v-7b1c3f4a] {
        background-color: #cde6fe;
}
