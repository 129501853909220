[data-v-59646276] .avue-crud__menu {
  display: none;
}
[data-v-59646276] .crud-header {
  background: #e0f0fd !important;
  color: #0074ff !important;
}
[data-v-59646276] .crud .el-select {
  width: 110px !important;
}
[data-v-59646276] .crud .input-with-select .el-input-group__append {
  background-color: #fff;
  color: #333;
}
[data-v-59646276] .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 12px;
}
.room-right[data-v-59646276] {
  height: 100%;
  background-color: #fff;
  width: 32%;
  min-width: 450px;
  margin-right: 10px;
  padding-top: 18px;
}
.room-right[data-v-59646276] .el-tabs {
    height: 100%;
}
.room-right[data-v-59646276] .el-tabs .el-tabs__content {
      height: calc(100% - 32px);
      overflow-y: auto;
}
.room-right[data-v-59646276] .el-tabs .el-tabs__header {
      margin-bottom: 0;
}
.room-right[data-v-59646276] .el-tabs .el-tabs__header .el-tabs__item {
        height: 32px;
        line-height: 32px;
        font-weight: 600;
}
.room-right[data-v-59646276] .el-tabs .el-tabs__header .el-tabs__item:hover {
          color: #3b94ff;
}
.room-right[data-v-59646276] .el-tabs .el-tabs__header .el-tabs__item.is-active {
          color: #3b94ff;
}
.room-right[data-v-59646276] .el-tabs .el-tabs__header .el-tabs__item:first-of-type {
          margin-left: 10px;
}
.room-right[data-v-59646276] .el-tabs .el-tabs__header .el-tabs__active-bar {
        background-color: #3b94ff;
        height: 1px;
}
.room-right[data-v-59646276] .el-tabs .el-tabs__header .el-tabs__nav-wrap::after {
        background-color: #d8d8d8;
        height: 1px;
}
.room-right[data-v-59646276] .el-tabs .pane-visit,
    .room-right[data-v-59646276] .el-tabs .medical-record {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      height: 100%;
}
.room-right[data-v-59646276] .el-tabs .pane-visit .chinese-tip,
      .room-right[data-v-59646276] .el-tabs .medical-record .chinese-tip {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        padding-left: 12px;
}
.room-right[data-v-59646276] .el-tabs .pane-visit .drug-table,
      .room-right[data-v-59646276] .el-tabs .medical-record .drug-table {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        overflow: auto;
}
.room-right[data-v-59646276] .el-tabs .pane-visit .btn-end,
      .room-right[data-v-59646276] .el-tabs .medical-record .btn-end {
        text-align: center;
        margin-right: 32px;
        padding: 10px 0;
}
.room-right[data-v-59646276] .el-tabs .pane-visit .btn-end button,
        .room-right[data-v-59646276] .el-tabs .medical-record .btn-end button {
          font-weight: bold;
}
.medical-record[data-v-59646276] .el-tabs {
  height: auto;
}
.medical-record[data-v-59646276] .el-tabs .el-tabs__content {
    height: auto;
}
.case-information .case-item[data-v-59646276] {
  padding-bottom: 10px;
  position: relative;
  border-bottom: 1px solid #d8d8d8;
}
.case-information .case-item[data-v-59646276]:first-of-type {
    border-top: 1px solid #d8d8d8;
}
.case-information .case-item .case-left .max-weights[data-v-59646276] {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    height: 28px;
    line-height: 28px;
    padding: 0 20px;
}
.case-information .case-item .case-left .max-weights .el-col[data-v-59646276] {
      height: 100%;
}
.case-information .case-item .case-left .max-weights .el-col > div[data-v-59646276] {
        height: 100%;
}
.case-information .case-item .case-left .max-weights label[data-v-59646276] {
      font-weight: 600;
}
.case-information .case-item .case-left .info-item[data-v-59646276] {
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    padding: 0 20px;
}
.case-information .case-item .case-right[data-v-59646276] {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
