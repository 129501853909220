[data-v-c7f5b4fe] .avue-crud__menu {
  display: none;
}
[data-v-c7f5b4fe] .el-dialog__body {
  padding: 20px 50px;
}
.edit-template[data-v-c7f5b4fe] {
  height: 600px;
}
.edit-template .template-content[data-v-c7f5b4fe] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.edit-template .template-content .template-left[data-v-c7f5b4fe] {
      width: 30%;
}
.edit-template .template-content .template-left .left-tip[data-v-c7f5b4fe] {
        font-size: 12px;
        color: #e42113;
        margin-left: 20px;
        margin-bottom: 20px;
        margin-top: 30px;
}
.edit-template .template-content .template-right[data-v-c7f5b4fe] {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      margin-left: 120px;
}
.edit-template .template-content .template-right .content-list[data-v-c7f5b4fe] {
        border: 1px solid #3b94ff;
        height: 200px;
        margin-bottom: 20px;
        overflow: auto;
        padding: 2px 0 6px;
}
.edit-template .template-content .template-right .content-list .list-item[data-v-c7f5b4fe] {
          margin-top: 10px;
          margin-left: 20px;
          margin-right: 20px;
          border-bottom: 1px dashed #333333;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
}
.edit-template .template-content .template-right .content-list .list-item .delete[data-v-c7f5b4fe] {
            color: #ff4a5a;
            cursor: pointer;
}
