[data-v-8f743c6c] .avue-crud__menu {
  display: none;
}
.main-container[data-v-8f743c6c] {
  padding: 4px;
  background-color: #d9d9d9;
}
.main-container .crud[data-v-8f743c6c] {
    margin-top: 30px;
    background-color: #fff;
    height: 300px;
    border-radius: 14px;
    padding: 20px;
}
