[data-v-18780cf3] .avue-crud__menu {
  display: none;
}
.main-container[data-v-18780cf3] {
  padding: 4px;
  background-color: #f9f9f9;
}
.main-container .crud[data-v-18780cf3] {
    margin-top: 30px;
    background-color: #fff;
    height: 300px;
    border-radius: 14px;
    padding: 20px;
}
