@media screen and (max-width: 1550px) {
label[data-v-b57118fe],
  .tag[data-v-b57118fe],
  span[data-v-b57118fe],
  .el-input[data-v-b57118fe] {
    font-size: 12px !important;
}
}
label[data-v-b57118fe] {
  font-size: 14px;
  font-weight: 400;
}
.patient-information[data-v-b57118fe] {
  padding: 4px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
.patient-information .patient-content[data-v-b57118fe] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.patient-information .patient-content .info-item[data-v-b57118fe] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      font-size: 14px;
      height: 22px;
      line-height: 22px;
      margin-bottom: 12px;
}
.patient-information .patient-content .info-item .el-input[data-v-b57118fe] {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        font-size: 14px;
}
.patient-information .patient-content .info-item .el-input[data-v-b57118fe] .el-input__inner {
          border: none;
          padding: 0;
          height: 22px;
          line-height: 22px;
          text-overflow: ellipsis;
}
.patient-information .patient-content .info-item[data-v-b57118fe] .input-red input {
        color: #f00;
}
.patient-information .right-icon[data-v-b57118fe] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 12px;
    color: #3b94ff;
    width: 42px;
    height: 20px;
    text-align: right;
}
.patient-information .right-icon i[data-v-b57118fe] {
      margin-left: 4px;
}
