[data-v-d8004748] .el-badge__content {
  top: 10px;
  right: 6px;
}
.drug-use-assistant[data-v-d8004748] {
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #fff;
}
.drug-use-assistant .assistant-title[data-v-d8004748] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
    border-bottom: 1px solid #d8d8d8;
    padding: 0 10px;
    padding-bottom: 5px;
    height: 30px;
}
.drug-use-assistant .assistant-title[data-v-d8004748] .el-image {
      width: 24px;
}
.drug-use-assistant .assistant-content[data-v-d8004748] {
    height: calc(100% - 60px);
    padding: 0 10px;
}
.drug-use-assistant .assistant-content[data-v-d8004748] .el-tabs {
      height: 100%;
}
.drug-use-assistant .assistant-content[data-v-d8004748] .el-tabs .el-tabs__content {
        height: calc(100% - 40px);
        overflow-y: auto;
}
.drug-use-assistant .assistant-content[data-v-d8004748] .el-tabs__header {
      margin-bottom: 6px;
}
.drug-use-assistant .assistant-content[data-v-d8004748] .el-tabs__item {
      color: #333;
      font-weight: 600;
}
.drug-use-assistant .assistant-content[data-v-d8004748] .el-tabs__item.is-active {
      color: #2d85ee;
}
.drug-use-assistant .assistant-content[data-v-d8004748] .el-tabs__active-bar {
      background-color: #2d85ee;
}
.drug-use-assistant .assistant-content[data-v-d8004748] .el-tabs__nav-wrap::after {
      background: #fff;
}
