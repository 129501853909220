@media screen and (max-width: 1550px) {
label[data-v-58dbbf11],
  .tag[data-v-58dbbf11],
  .el-input[data-v-58dbbf11] {
    font-size: 12px !important;
}
}
label[data-v-58dbbf11] {
  font-size: 14px;
  font-weight: 400;
}
.visit-prescrib[data-v-58dbbf11] {
  padding: 0 12px;
  font-size: 14px;
}
.visit-prescrib .patient-info[data-v-58dbbf11] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 0;
    margin-bottom: 10px;
    font-size: 18px;
}
.visit-prescrib .patient-info span[data-v-58dbbf11] {
      margin-right: 30px;
}
.visit-prescrib .diagnose-tags[data-v-58dbbf11] {
    font-size: 14px;
    margin-top: 10px;
}
.visit-prescrib .diagnose-tags .diagnose-title[data-v-58dbbf11] {
      padding: 6px 0;
}
.visit-prescrib .diagnose-tags .max-weights[data-v-58dbbf11] {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
}
.visit-prescrib .diagnose-tags .max-weights[data-v-58dbbf11]:nth-child(1) {
        font-weight: 600;
}
.visit-prescrib .diagnose-tags .max-weights:nth-child(1) label[data-v-58dbbf11] {
          font-size: 18px !important;
}
.visit-prescrib .diagnose-tags .max-weights:nth-child(1) .el-input[data-v-58dbbf11] {
          font-size: 18px;
}
.visit-prescrib .diagnose-tags .max-weights:nth-child(1) span[data-v-58dbbf11] {
          font-size: 18px;
}
.visit-prescrib .diagnose-tags .max-weights[data-v-58dbbf11]:nth-child(2) {
        height: 32px;
        line-height: 32px;
}
.visit-prescrib .diagnose-tags .max-weights:nth-child(2) label[data-v-58dbbf11] {
          font-weight: normal;
          font-size: 14px;
}
.visit-prescrib .diagnose-tags .max-weights:nth-child(2) .el-input[data-v-58dbbf11] {
          font-size: 14px;
}
.visit-prescrib .diagnose-tags .max-weights:nth-child(2) span[data-v-58dbbf11] {
          font-size: 14px;
}
.visit-prescrib .diagnose-tags .max-weights .el-col[data-v-58dbbf11] {
        height: 100%;
}
.visit-prescrib .diagnose-tags .max-weights .el-col > div[data-v-58dbbf11] {
          height: 100%;
}
.visit-prescrib .diagnose-tags .max-weights label[data-v-58dbbf11] {
        font-weight: 600;
        font-size: 16px;
}
.visit-prescrib .diagnose-tags .max-weights .el-input[data-v-58dbbf11] {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        font-size: 14px;
}
.visit-prescrib .diagnose-tags .max-weights .el-input[data-v-58dbbf11] .el-input__inner {
          border: none;
}
.visit-prescrib .diagnose-tags .max-weights[data-v-58dbbf11] .input-red input {
        color: #f00;
}
.visit-prescrib .diagnose-tags .diagnose[data-v-58dbbf11],
    .visit-prescrib .diagnose-tags .symptoms[data-v-58dbbf11] {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
}
.visit-prescrib .diagnose-tags .diagnose label[data-v-58dbbf11],
      .visit-prescrib .diagnose-tags .symptoms label[data-v-58dbbf11] {
        height: 32px;
        line-height: 32px;
}
.visit-prescrib .diagnose-tags .diagnose .tag[data-v-58dbbf11],
      .visit-prescrib .diagnose-tags .symptoms .tag[data-v-58dbbf11] {
        height: 32px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border: 1px solid #d8d8d8;
        padding: 0 5px;
        margin-right: 10px;
        margin-bottom: 4px;
}
.visit-prescrib .diagnose-tags .diagnose .tag.select-tag[data-v-58dbbf11],
        .visit-prescrib .diagnose-tags .symptoms .tag.select-tag[data-v-58dbbf11] {
          background: #e8f6ff;
          border: 1px solid #3b94ff;
          color: #3b94ff;
}
.visit-prescrib .diagnose-tags .diagnose .tag[data-v-58dbbf11]:hover,
        .visit-prescrib .diagnose-tags .symptoms .tag[data-v-58dbbf11]:hover {
          border: 1px solid #3b94ff;
          color: #3b94ff;
}
.visit-prescrib .diagnose-tags .diagnose .tag span[data-v-58dbbf11],
        .visit-prescrib .diagnose-tags .symptoms .tag span[data-v-58dbbf11] {
          cursor: pointer;
          display: block;
          max-width: 90px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
}
.visit-prescrib .diagnose-tags .diagnose .tag .el-input[data-v-58dbbf11] .el-input__inner,
        .visit-prescrib .diagnose-tags .symptoms .tag .el-input[data-v-58dbbf11] .el-input__inner {
          border: none;
          padding: 0;
}
.visit-prescrib .diagnose-tags .diagnose .tag .close[data-v-58dbbf11],
        .visit-prescrib .diagnose-tags .symptoms .tag .close[data-v-58dbbf11] {
          color: #3b94ff;
          font-size: 16px;
          cursor: pointer;
          margin-left: 6px;
}
.visit-prescrib .diagnose-tags .diagnose .tag .close[data-v-58dbbf11]:hover,
          .visit-prescrib .diagnose-tags .symptoms .tag .close[data-v-58dbbf11]:hover {
            color: #e40013;
}
.visit-prescrib .diagnose-tags .diagnose .no[data-v-58dbbf11],
      .visit-prescrib .diagnose-tags .symptoms .no[data-v-58dbbf11] {
        line-height: 32px;
}
.visit-prescrib .diagnose-tags .diagnose[data-v-58dbbf11] .el-select .el-input__inner,
      .visit-prescrib .diagnose-tags .symptoms[data-v-58dbbf11] .el-select .el-input__inner {
        height: 32px !important;
        line-height: 32px;
}
.visit-prescrib .diagnose-tags .diagnose .diagnose-content[data-v-58dbbf11],
      .visit-prescrib .diagnose-tags .symptoms .diagnose-content[data-v-58dbbf11] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
}
.visit-prescrib .diagnose-tags .diagnose .showicd[data-v-58dbbf11],
      .visit-prescrib .diagnose-tags .symptoms .showicd[data-v-58dbbf11] {
        width: 76px;
        -ms-flex-item-align: center;
            align-self: center;
}
.visit-prescrib .diagnose-tags .diagnose .right-icon[data-v-58dbbf11],
      .visit-prescrib .diagnose-tags .symptoms .right-icon[data-v-58dbbf11] {
        color: #3b94ff;
        margin-top: 5px;
}
.visit-prescrib .add-diagnose[data-v-58dbbf11] {
    margin: 10px 0;
}
.visit-prescrib .diagnose-table[data-v-58dbbf11] {
    margin-top: 20px;
}
.visit-prescrib .diagnose-table .delete[data-v-58dbbf11] {
      color: #999;
}
.visit-prescrib .diagnose-table .delete[data-v-58dbbf11]:hover {
        color: #e40013;
}
